import * as React from 'react';
import moment from 'moment';
import { Button, Col, Row, Skeleton, Spin, Table } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { TSInput } from '../common/TSInput';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { DateService } from '../../service/DateService';
import { BranchCategory, ProductNodeConnection } from '../../schema';
import { getValue } from '../common/AutoCompleteHelper';
import { newStocksQuery } from '../master/Product/constants';
import { CompanyService } from '../../service/CompanyService';
import { Pagination } from 'sha-el-design/lib';
import { downloadToFile } from '../helper';
import { NEW_STOCK_REPORT_CSV } from '../../config';

export class JharcraftNewStockSummary extends React.Component<Props, State> {
  companyService = new CompanyService();
  dateService = new DateService();

  constructor(props: Props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      barcode: '',
      branchId: null,
      isLoading: false,
      company: null,
      business: null,
      companyId: '',
      message: '',
      branchName: 'Head Office',
    };
  }

  componentWillMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((business) => {
      if (business) {
        const companyId =
          business.type === 'company' ? business.id : business.companyId;
        const branchId = business.type === 'branch' ? business.id : null;
        companyId && this.setState({ companyId, branchId, business });
        if (branchId !== null) this.setState({ branchName: business.name });
      }
    });
  }

  componentWillUnmount() {
    this.dateService?.date$.next(moment().format('YYYY-MM-DD'));
  }

  columns = () => [
    {
      title: 'Physical Stock Entry Date',
      dataIndex: 'node.newStock.date',
      key: 'date',
    },
    {
      title: 'Product',
      dataIndex: 'node.name',
      key: 'name',
    },
    {
      title: 'Barcode 1',
      dataIndex: 'node.oldBarcodeId',
      key: 'oldBarcode',
    },
    {
      title: 'Barcode 2',
      dataIndex: 'node.newBarcodeId',
      key: 'newBarcode',
    },
    {
      title: 'Category',
      dataIndex: 'node.category.name',
      key: 'name',
    },
    {
      title: 'Cost Price',
      dataIndex: 'node.newStock.purchaseRate',
      key: 'purchaseRate',
    },
    {
      title: 'MRP',
      dataIndex: 'node.newStock.mrp',
      key: 'mrp',
    },
    {
      title: 'Stock',
      dataIndex: 'node.newStock',
      key: 'totalStocks',
      render: (data) => (
        <span
          style={{
            color: data?.physicalStockPresent ? 'black' : 'red',
          }}
        >
          {Number(Number(data?.totalStocks).toFixed(2))}
        </span>
      ),
    },
    {
      title: 'Cost Price Value',
      dataIndex: 'node.newStock.totalStocksPurchaseValue',
      key: 'totalStocksPurchaseValue',
      render: (data) => Number(Number(data).toFixed(2)),
    },
    {
      title: 'MRP Value',
      dataIndex: 'node.newStock.totalStocksValue',
      key: 'totalStocksValue',
      render: (data) => Number(Number(data).toFixed(2)),
    },
  ];

  downloadCsv = () => {
    let queryParams = `companyId=${this.state.companyId}&date=${this.state.date}`;
    queryParams +=
      this.state.branchId !== null ? `&branchId=${this.state.branchId}` : '';

    this.setState({ isLoading: true, message: 'Please Wait' });

    fetch(NEW_STOCK_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) => {
        if (csv === 'Wait') {
          this.setState({
            message: 'Generating data. You can downlaod it after 10 to 15 mins',
          });
        } else if (csv === 'Creating') {
          this.setState({
            message: 'You have already requested for this data. Please wait!',
          });
        } else {
          const data = csv.split('####');
          downloadToFile(
            data[0],
            `stock-report-${new Date()}-${btoa(data[1])}`,
            'STOCK REPORT',
            this.state.business?.type === 'branch'
              ? this.state.business.companyName || ''
              : this.state.business?.name || '',
            this.state.branchName,
            moment(this.state.date).format('DD-MM-YYYY'),
            moment(this.state.date).format('DD-MM-YYYY'),
            '',
          );
          this.setState({ message: '' });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { date, currentPage, after, branchId, branchName } = this.state;
    const { companyId, business } = this.state;
    const variables = {
      date,
      companyId,
      branchId,
      first: 20,
      after,
      mostUsedProduct: true,
    };
    const { barcode } = this.state;
    return (
      <>
        <Row>
          <Col span={5}>
            <TSInput error="" value={this.state.date} addonBefore="Date" />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={barcode}
              onChange={(e) => this.setState({ barcode: e.target.value })}
              name="barcode"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
          <Col span={7}>
            <TSInput
              error=""
              value={branchName}
              name="locationName"
              type="text"
              addonBefore="Location Name"
            />
          </Col>
          {business?.type === 'company' && (
            <Col span={8}>
              <TSAutoComplete
                error={''}
                inputProps={{
                  name: 'branchId',
                  addonBefore: 'Change Location',
                }}
                dataSource={
                  business.branches?.edges.filter(
                    (element) =>
                      element.node.category === BranchCategory.EMPORIUM,
                  ) || []
                }
                key="id"
                name="branchId"
                onValueSelect={(e) =>
                  this.setState({
                    branchId: e.target.value,
                    branchName: getValue(
                      business.branches?.edges,
                      e.target.value,
                    ),
                  })
                }
                displayKey="name"
                textValue={getValue(business.branches?.edges, branchId)}
              />
            </Col>
          )}
          {business?.type === 'company' && (
            <Col span={3}>
              <Button
                children="Clear Location"
                onClick={() =>
                  this.setState({ branchId: null, branchName: 'Head Office' })
                }
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          )}
        </Row>
        <GraphqlQuery
          queryString={newStocksQuery}
          variables={barcode ? { ...variables, barcode } : variables}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton />;
            }
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProducts.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                />,
                <Pagination
                  key="pagination"
                  totalCount={response.allProducts.totalCount || 0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  key="download"
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() => this.downloadCsv()}
                  disabled={this.state.isLoading}
                />,
                <span style={{ float: 'right', margin: '7px' }}>
                  {this.state.message}
                </span>,
              ];
            }
            return <Spin />;
          }}
        />
      </>
    );
  }
}

interface State {
  date: string;
  currentPage: number;
  after: string;
  barcode: string;
  branchId?: string | null;
  isLoading: boolean;
  company: CompanyNodeEdge | null;
  companyId: string;
  business: Bussiness | null;
  message: string;
  branchName: string;
}

interface Props {}
