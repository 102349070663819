import * as React from 'react';
import { Tabs, Icon, Spin } from 'antd';
import { CompanyService } from '../../service/CompanyService';
import moment from 'moment';
import { BranchCategory } from '../../schema';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { CategoryWiseStockReport } from './CategoryWiseStockReport';
import { ProductStockForCategoryReport } from './ProductStockForCategory';
import { CategoryWiseMonthlySaleReport } from './CategoryWiseMonthlySaleReport';
import { StockReportForProductsWithoutBarcode } from '../Production/Production/StockReportForProductsWithoutBarcode';
import { UserService } from '../../service/UserService';
import { UserNodeEdge } from '../user/constants';
import { ItemWiseTotalStockReport } from './ItemWiseTotalStock';
import { FinishedStockReport } from './FinishedStockReport';
import { RawStockReport } from './RawStockReport';
import { VendorWiseStockReport } from './VendorWiseStockReport';
import { StockSummary } from './StockSummary';
import { StockSummaryDateRange } from './StockSummaryDateRange';
import { JharcraftNewStockSummary } from './JharcraftNewStockSummary';
import { JharcraftCategoryWiseStockReport } from './JharcraftCategoryWiseStockReport';
import { MultiBranchStockReport } from './MultiBranchStockReport';

export class StockReport extends React.Component<{}, State> {
  companyService = new CompanyService();
  userService = new UserService();

  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      companyId: '',
      branchId: '',
      business: null,
      categoryId: '',
      categoryName: '',
      categoryDate: moment().format('YYYY-MM-DD'),
      company: null,
      user: null,
      fy_stock: true,
    };
  }

  componentWillMount() {
    this.companyService?.company$.subscribe((company) =>
      this.setState({ company }),
    );
    this.companyService?.selectedBussiness$.subscribe((business) => {
      if (business) {
        const companyId =
          business.type === 'company' ? business.id : business.companyId;
        const branchId = business.type === 'branch' ? business.id : null;
        companyId && this.setState({ companyId, branchId, business });
      }
    });
    this.userService?.user$.subscribe((user) =>
      this.setState({ user: user?.user }),
    );
  }

  handleTabChange = (activeKey) => {
    this.setState({ activeKey });
  };

  handleCategoryData = (
    categoryId: string,
    categoryName: string,
    activeKey: string,
    categoryDate: string,
    fy_stock?: boolean,
  ) => {
    this.setState({ categoryId, categoryName, activeKey, categoryDate });
  };

  render() {
    if (!this.state.business || !this.state.company) return <Spin />;
    const { companyId, branchId, business } = this.state;

    return (
      <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
        {!this.state.company.name.toLowerCase().includes('tiles') && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Stock Summary
              </span>
            }
            key="1"
            closable={false}
          >
            {this.state.company.name.toLowerCase().includes('jharcraft') ||
            this.state.company?.name.toLowerCase().includes('khadi') ? (
              <JharcraftNewStockSummary />
            ) : (
              <StockSummary
                companyId={companyId}
                branchId={branchId}
                business={business}
                company={this.state.company}
              />
            )}
          </Tabs.TabPane>
        )}
        {(this.state.company.name.toLowerCase().includes('jharcraft') ||
          this.state.company?.name.toLowerCase().includes('khadi') ||
          this.state.company?.name.toLowerCase().includes('kraftribe')) && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Stock Summary (Date Range)
              </span>
            }
            key="10"
            closable={false}
          >
            <StockSummaryDateRange
              companyId={companyId}
              branchId={branchId}
              business={business}
              company={this.state.company}
            />
          </Tabs.TabPane>
        )}
        {(this.state.company.name.toLowerCase().includes('jharcraft') ||
          this.state.company?.name.toLowerCase().includes('khadi')) &&
          this.state.business.type === 'company' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Multiple Branch Stock Report
                </span>
              }
              key="11"
              closable={false}
            >
              <MultiBranchStockReport
                companyId={companyId}
                business={business}
              />
            </Tabs.TabPane>
          )}
        {!this.state.company.name.toLowerCase().includes('tiles') && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Category Wise Stock Report
              </span>
            }
            key="2"
            closable={false}
          >
            {this.state.company.name.toLowerCase().includes('jharcraft') ||
            this.state.company?.name.toLowerCase().includes('khadi') ? (
              <JharcraftCategoryWiseStockReport
                companyId={companyId}
                branchId={branchId}
                business={business}
                company={this.state.company}
              />
            ) : (
              <CategoryWiseStockReport
                companyId={companyId}
                branchId={branchId}
                business={business}
                handleCategoryData={this.handleCategoryData}
                fy_stock={false}
                company={this.state.company}
              />
            )}
          </Tabs.TabPane>
        )}
        {!this.state.company.name.toLowerCase().includes('jharcraft') &&
          !this.state.company?.name.toLowerCase().includes('khadi') && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Category Wise Financial Year Stock Report
                </span>
              }
              key="9"
              closable={false}
            >
              <CategoryWiseStockReport
                companyId={companyId}
                branchId={branchId}
                business={business}
                handleCategoryData={this.handleCategoryData}
                fy_stock={true}
                company={this.state.company}
              />
            </Tabs.TabPane>
          )}
        {this.state.activeKey === '3' && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Product Stock For Category Report
              </span>
            }
            key="3"
            closable={true}
          >
            <ProductStockForCategoryReport
              companyId={companyId}
              branchId={branchId}
              business={business}
              categoryId={this.state.categoryId}
              categoryName={this.state.categoryName}
              date={this.state.categoryDate}
              fy_stock={this.state.fy_stock}
            />
          </Tabs.TabPane>
        )}
        {this.state.business.type === 'branch' &&
          !this.state.business.companyName?.includes('Seva Sadan') &&
          !this.state.company.name.toLowerCase().includes('jharcraft') &&
          !this.state.company?.name.toLowerCase().includes('khadi') && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="table" />
                  Category Wise Monthly Sales Report
                </span>
              }
              key="4"
              closable={false}
            >
              <CategoryWiseMonthlySaleReport
                companyId={companyId}
                branchId={branchId}
                business={business}
              />
            </Tabs.TabPane>
          )}
        {business.type === 'company' &&
          this.state.user?.user?.groups?.[0]?.name === 'Production' && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="report" />
                  Stock Report for Products without Barcode
                </span>
              }
              key="5"
            >
              <StockReportForProductsWithoutBarcode
                company={this.state.company}
                business={this.state.business}
              />
            </Tabs.TabPane>
          )}
        {business.type === 'branch' &&
          business.category === BranchCategory.FINISHING_UNIT && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="report" />
                  Stock Report for Products without Barcode
                </span>
              }
              key="5"
            >
              <StockReportForProductsWithoutBarcode
                company={this.state.company}
                branch={this.state.business}
              />
            </Tabs.TabPane>
          )}
        {business.type === 'company' &&
          this.state.user?.user?.groups?.[0]?.name === 'Inventory' &&
          !this.state.company.name.toLowerCase().includes('tiles') &&
          !this.state.company.name.toLowerCase().includes('jharcraft') &&
          !this.state.company?.name.toLowerCase().includes('khadi') && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="report" />
                  Item wise Total Stock
                </span>
              }
              key="5"
            >
              <ItemWiseTotalStockReport company={this.state.company} />
            </Tabs.TabPane>
          )}
        {business.type === 'company' &&
          this.state.user?.user?.groups?.[0]?.name === 'Inventory' &&
          !this.state.company.name.toLowerCase().includes('tiles') &&
          !this.state.company.name.toLowerCase().includes('jharcraft') &&
          !this.state.company?.name.toLowerCase().includes('khadi') && (
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="report" />
                  Vendor Wise total Stock
                </span>
              }
              key="6"
            >
              <VendorWiseStockReport company={this.state.company} />
            </Tabs.TabPane>
          )}
        {this.state.company.name.toLowerCase().includes('tiles') && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Finished Stock Report
              </span>
            }
            key="1"
            closable={false}
          >
            <FinishedStockReport
              companyId={companyId}
              branchId={branchId}
              business={business}
              handleCategoryData={this.handleCategoryData}
            />
          </Tabs.TabPane>
        )}

        {this.state.company.name.toLowerCase().includes('tiles') && (
          <Tabs.TabPane
            tab={
              <span>
                <Icon type="table" />
                Raw Material Stock Report
              </span>
            }
            key="8"
            closable={false}
          >
            <RawStockReport
              companyId={companyId}
              branchId={branchId}
              business={business}
              handleCategoryData={this.handleCategoryData}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    );
  }
}

interface State {
  activeKey: string;
  companyId: string;
  branchId?: string | null;
  business: Bussiness | null;
  categoryId: string;
  categoryName: string;
  categoryDate: string;
  company: CompanyNodeEdge | null;
  user?: UserNodeEdge | null;
  fy_stock: boolean;
}
