import * as React from 'react';
import moment from 'moment';
import { Button, Col, DatePicker, Row, Skeleton, Spin, Table } from 'antd';
import { GraphqlQuery } from 'requestapijs';
import { TSAutoComplete } from '../common/TSAutoComplete';
import { TSInput } from '../common/TSInput';
import { Bussiness, CompanyNodeEdge } from '../master/Company/constants';
import { BranchCategory, ProductNodeConnection } from '../../schema';
import { getValue } from '../common/AutoCompleteHelper';
import { stockWithDateRangeQuery } from '../master/Product/constants';
import { Pagination } from 'sha-el-design/lib';
import { NEW_STOCK_REPORT_CSV } from '../../config';
import { downloadToFile } from '../helper';

export class StockSummaryDateRange extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      date: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      after: '',
      barcode: '',
      branchId: props.branchId,
      isLoading: false,
      message: '',
      branchName: props.branchId ? props.business.name : 'Head Office',
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  columns = () => {
    const stockSummaryColumns: {
      title: string;
      dataIndex: string;
      key: string;
      render?: (data: any) => string | Number | JSX.Element;
    }[] = [];

    if (this.props.company.name.toLowerCase().includes('jharcraft')) {
      stockSummaryColumns.push({
        title: 'Physical Stock Entry Date',
        dataIndex: 'node.stockDateRange.date',
        key: 'date',
      });
    }

    stockSummaryColumns.push(
      {
        title: 'Product',
        dataIndex: 'node.name',
        key: 'name',
      },
      {
        title: 'Barcode 1',
        dataIndex: 'node.oldBarcodeId',
        key: 'oldBarcode',
      },
      {
        title: 'Barcode 2',
        dataIndex: 'node.newBarcodeId',
        key: 'newBarcode',
      },
      {
        title: 'Category',
        key: 'category',
        dataIndex: 'node.category.name',
      },
      {
        title: 'Division',
        key: 'division',
        dataIndex: 'node.division',
      },
      {
        title: 'Unit',
        key: 'unit',
        dataIndex: 'node.unit.name',
      },
      {
        title: 'MRP',
        key: 'sellingRate',
        dataIndex: 'node.sellingRate',
      },
      {
        title: 'Opening Stock',
        dataIndex: 'node.stockDateRange.openingStock',
        key: 'openingStock',
      },
      {
        title: 'Opening Stock Value',
        dataIndex: 'node',
        key: 'openingStockValue',
        render: (data) =>
          data?.stockDateRange?.openingStock * data?.sellingRate,
      },
      {
        title: 'Inward Qty',
        dataIndex: 'node.stockDateRange.inwardQty',
        key: 'inwardQty',
      },
      {
        title: 'Inward Value',
        dataIndex: 'node',
        key: 'inwardValue',
        render: (data) => data?.stockDateRange?.inwardQty * data?.sellingRate,
      },
      {
        title: 'Outward Qty',
        dataIndex: 'node.stockDateRange.outwardQty',
        key: 'outwardQty',
      },
      {
        title: 'Outward Value',
        dataIndex: 'node',
        key: 'outwardValue',
        render: (data) => data?.stockDateRange?.outwardQty * data?.sellingRate,
      },
      {
        title: 'Closing Stocks',
        dataIndex: 'node.stockDateRange',
        key: 'totalStocks',
        render: (data) => (
          <span
            style={{
              color:
                this.props.company.name.toLowerCase().includes('jharcraft') &&
                !data?.physicalStockPresent
                  ? 'red'
                  : 'black',
            }}
          >
            {Number(Number(data?.totalStocks).toFixed(2))}
          </span>
        ),
      },
      {
        title: 'Closing Stocks Value',
        dataIndex: 'node',
        key: 'closingValue',
        render: (data) => data?.stockDateRange?.totalStocks * data?.sellingRate,
      },
    );

    return stockSummaryColumns;
  };

  downloadCsv = (variables) => {
    const { startDate, date, companyId, branchId } = variables;
    let queryParams = `companyId=${companyId}&startDate=${startDate}&date=${date}`;
    queryParams += branchId !== null ? `&branchId=${branchId}` : '';

    this.setState({ isLoading: true, message: 'Please Wait' });

    fetch(NEW_STOCK_REPORT_CSV + '?' + queryParams)
      .then((value) => value.text())
      .then((csv) => {
        if (csv === 'Wait') {
          this.setState({
            message: 'Generating data. You can downlaod it after 10 to 15 mins',
          });
        } else if (csv === 'Creating') {
          this.setState({
            message: 'You have already requested for this data. Please wait!',
          });
        } else {
          const data = csv.split('####');
          downloadToFile(
            data[0],
            `stock-summary-date-range-report-${new Date()}-${btoa(data[1])}`,
            'STOCK SUMMARY (DATE RANGE) REPORT',
            this.props.business.type === 'branch'
              ? this.props.business.companyName || ''
              : this.props.business.name,
            this.state.branchName,
            moment(this.state.startDate).format('DD-MM-YYYY'),
            moment(this.state.date).format('DD-MM-YYYY'),
            '',
          );
          this.setState({ message: '' });
        }
      })
      .then(() => this.setState({ isLoading: false }));
  };

  afterStack = [''];
  onPageChange = (next: boolean, after: string, nextPage: boolean) => {
    if (next && nextPage) {
      this.setState({ after, currentPage: this.state.currentPage + 1 });
      this.afterStack.push(after);
    } else if (!next && this.afterStack.length > 1) {
      this.afterStack.pop();
      this.setState({
        after: this.afterStack[this.afterStack.length - 1],
        currentPage: this.state.currentPage - 1,
      });
    }
  };

  render() {
    const { startDate, date, currentPage, after, branchId, branchName } =
      this.state;
    const { companyId, business } = this.props;
    const variables = {
      startDate,
      date,
      companyId,
      branchId,
      first: 20,
      after,
      mostUsedProduct: true,
    };
    const { barcode } = this.state;
    return (
      <>
        <Row>
          <Col span={4}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={
                (this.state.startDate && moment(this.state.startDate)) || null
              }
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.date) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.date).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={4}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(this.state.date && moment(this.state.date)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'date',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <TSInput
              error=""
              value={barcode}
              onChange={(e) => this.setState({ barcode: e.target.value })}
              name="barcode"
              type="text"
              addonBefore="Search Product"
              placeholder="Enter Barcode or Name"
            />
          </Col>
          <Col span={7}>
            <TSInput
              error=""
              value={branchName}
              name="locationName"
              type="text"
              addonBefore="Location Name"
            />
          </Col>
          {business.type === 'company' && (
            <Col span={8}>
              <TSAutoComplete
                error={''}
                inputProps={{
                  name: 'branchId',
                  addonBefore: 'Change Location',
                }}
                dataSource={
                  business.branches?.edges.filter(
                    (element) =>
                      element.node.category === BranchCategory.EMPORIUM,
                  ) || []
                }
                key="id"
                name="branchId"
                onValueSelect={(e) =>
                  this.setState({
                    branchId: e.target.value,
                    branchName: getValue(
                      business.branches?.edges,
                      e.target.value,
                    ),
                  })
                }
                displayKey="name"
                textValue={getValue(business.branches?.edges, branchId)}
              />
            </Col>
          )}
          {business.type === 'company' && (
            <Col span={3}>
              <Button
                children="Clear Location"
                onClick={() =>
                  this.setState({ branchId: null, branchName: 'Head Office' })
                }
                style={{ marginTop: '5px', width: '100%' }}
              />
            </Col>
          )}
        </Row>
        <GraphqlQuery
          queryString={stockWithDateRangeQuery}
          variables={barcode ? { ...variables, barcode } : variables}
          render={(
            response: { allProducts: ProductNodeConnection },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton />;
            }
            if (error) {
              return <h3>Something Went Wrong!!</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.allProducts.edges}
                  columns={this.columns()}
                  rowKey={(obj) => obj.node?.id || ''}
                  pagination={false}
                  scroll={{ x: true }}
                />,
                <Pagination
                  key="pagination"
                  totalCount={response.allProducts.totalCount || 0}
                  currentPage={currentPage}
                  batchSize={20}
                  cursorBasedPagination
                  onChange={(_p, _ps, next) =>
                    this.onPageChange(
                      next,
                      response.allProducts.pageInfo.endCursor,
                      response.allProducts.pageInfo.hasNextPage,
                    )
                  }
                  style={{ float: 'right' }}
                />,
                <Button
                  key="download"
                  style={{ float: 'right' }}
                  icon="download"
                  onClick={() => this.downloadCsv(variables)}
                  disabled={this.state.isLoading}
                />,
                <span style={{ float: 'right', margin: '7px' }}>
                  {this.state.message}
                </span>,
              ];
            }
            return <Spin />;
          }}
        />
      </>
    );
  }
}

interface State {
  startDate: string;
  date: string;
  currentPage: number;
  after: string;
  barcode: string;
  branchId?: string | null;
  isLoading: boolean;
  message: string;
  branchName: string;
}

interface Props {
  company: CompanyNodeEdge;
  companyId: string;
  branchId?: string | null;
  business: Bussiness;
}
