import { CompanyNodeEdge } from '../master/Company/constants';
import { CustomerNodeEdge } from '../master/Customer/constants';
import { ProductNodeEdge } from '../master/Product/constants';
import { BranchNodeEdge, category } from '../master/Branch/constants';
import moment from 'moment';
import { VendorNode } from '../master/vendor/constants';
import { SalesPersonNodeEdge } from '../master/SalesPerson/constants';

export interface ProductDetailNodeEdge {
  id?: string | null;
  date?: string | null;
  product?: ProductNodeEdge;
  productId?: string | null;
  rate: number | null;
  purchaseRate?: number | null;
  gstRate?: number | null;
  discount?: number | null;
  quantity?: number | null;
  amount: number | null;
  movementType:
    | 'UP'
    | 'DOWN'
    | 'SALE'
    | 'PURCHASE'
    | 'DAMAGE'
    | 'PURCHASE_RETURN'
    | 'EXCHANGE'
    | 'APPROVAL'
    | 'APPROVAL_RETURN'
    | 'OPENING_STOCK'
    | 'GIFT'
    | 'CONVERSION'
    | 'SALE_CANCEL'
    | 'EXCHANGE_CANCEL'
    | 'CODE_CONVERSION'
    | 'REQUEST'
    | 'VALUEADITION'
    | 'VALUEADITION_RETURN'
    | 'SERVICE';
  movementFor?: BranchNodeEdge;
  movementForId?: string | null;
  movementFrom?: BranchNodeEdge;
  movementFromId?: string | null;
  vendorFor?: VendorNode;
  vendorForId?: string;
  remark?: string;
  wastage?: number;
  status?: 'ACCEPTED' | 'REJECTED' | 'PENDING';
  availableQuantityForJobOrder?: [
    {
      jobOrderId: string;
      quantity: number;
    },
  ];
  company?: CompanyNodeEdge;
  companyId?: string | null;
}

export interface ProductError {
  productId: string;
  rate: string;
  gstRate?: string;
  discount: string;
  quantity: string;
  amount: string;
  movementType: string;
  movementForId: string;
}

export const productErrorObject: ProductError = {
  productId: '',
  rate: '',
  gstRate: '',
  discount: '',
  quantity: '',
  amount: '',
  movementType: '',
  movementForId: '',
};

export interface ProductDetailsEdges {
  cursor: string;
  node: ProductDetailNodeEdge;
}

export interface ProductDetailsNodeConnection {
  edges: ProductDetailsEdges[];
}

export interface PaymentModeNodeEdge {
  date: string;
  paymentType: 'Cash' | 'Card' | 'Credit' | 'Cheque' | 'Bank';
  amount: number;
}

export interface PaymentModeNodeConnection {
  edges: {
    cursor?: string;
    node: PaymentModeNodeEdge;
  }[];
}

export interface SalesNodeEdgeQuery {
  id?: string;
  date: string;
  customer?: CustomerNodeEdge;
  productDetails?: ProductDetailsNodeConnection;
  billNo: string;
  branchBillNo?: string;
  billAmount: number;
  discount: number;
  redeemablePoint: number;
  toRedeem: boolean;
  redeemValue: number;
  grossAmount: number;
  cgst: number;
  sgst: number;
  netAmount: number;
  totalAmount?: number;
  paymentModes: PaymentModeNodeConnection;
  customerPaid?: number;
  balanceRefund?: number;
  chequeNumber?: string;
  bankName?: string;
  gstinLegalName?: string;
  gstinNumber?: string;
  remarks?: string;
  isValueAddition?: boolean;
  valueAdditionRemarks?: string;
  valueAdditionAmount?: number;
  isExchange: boolean;
  oldBillNo?: string;
  oldProductDetails?: ProductDetailsNodeConnection;
  cancelled?: boolean;
  saleBy?: string;
  billBy?: string;
  salesPerson?: SalesPersonNodeEdge;
  company?: CompanyNodeEdge;
  branch?: BranchNodeEdge;
}

export interface SalesNodeEdgeMutation {
  id?: string;
  date: string;
  customerId?: string | null;
  productDetails?: SalesProductDetails[];
  billNo: string;
  branchBillNo?: string;
  billAmount: number | null;
  discount: number;
  redeemablePoint: number;
  toRedeem: boolean;
  redeemValue: number;
  grossAmount: number | null;
  cgst: number | null;
  sgst: number | null;
  netAmount: number | null;
  totalAmount?: number | null;
  paymentModes: PaymentModeNodeEdge[];
  customerPaid: number | null;
  balanceRefund: number | null;
  chequeNumber?: string;
  bankName?: string;
  gstinLegalName?: string;
  gstinNumber?: string;
  remarks?: string;
  isValueAddition: boolean;
  valueAdditionRemarks: string;
  valueAdditionAmount: number | null;
  isExchange: boolean;
  oldBillNo: string;
  oldProductDetails?: ProductDetailNodeEdge[];
  cancelled: boolean;
  saleBy?: string;
  billBy?: string;
  salesPersonId?: string | null;
  companyId?: string | null;
  branchId?: string | null;
}

export interface SalesProductDetails extends ProductDetailNodeEdge {
  totalStock?: number;
  allowDecimal?: boolean;
}

export interface SalesError {
  date: string;
  customerId: string;
  productDetails: {
    productId: string;
    rate: string;
    discount: string;
    gstRate: string;
    amount: string;
    quantity: string;
  }[];
  billNo: string;
  billAmount: string;
  discount: string;
  redeemablePoint: string;
  toRedeem: string;
  redeemValue: string;
  grossAmount: string;
  cgst: string;
  sgst: string;
  netAmount: string;
  paymentModes: [
    {
      paymentType: string;
      amount: string;
    },
  ];
  gstinLegalName: string;
  gstinNumber: string;
  remarks: string;
  isValueAddition: string;
  valueAdditionRemarks: string;
  valueAdditionAmount: string;
  isExchange: string;
  oldBillNo: string;
  oldProductDetails: {
    productId: string;
    rate: string;
    amount: string;
    quantity: string;
  }[];
  salesPersonId: string;
}

export const salesErrorObject: SalesError = {
  date: '',
  customerId: '',
  productDetails: [
    {
      productId: '',
      rate: '',
      quantity: '',
      discount: '',
      gstRate: '',
      amount: '',
    },
  ],
  billNo: '',
  billAmount: '',
  discount: '',
  redeemablePoint: '',
  toRedeem: '',
  redeemValue: '',
  grossAmount: '',
  cgst: '',
  sgst: '',
  netAmount: '',
  paymentModes: [
    {
      paymentType: '',
      amount: '',
    },
  ],
  gstinLegalName: '',
  gstinNumber: '',
  remarks: '',
  isValueAddition: '',
  valueAdditionRemarks: '',
  valueAdditionAmount: '',
  isExchange: '',
  oldBillNo: '',
  oldProductDetails: [
    {
      productId: '',
      rate: '',
      quantity: '',
      amount: '',
    },
  ],
  salesPersonId: '',
};

export const salesObject: SalesNodeEdgeMutation = {
  date: moment().format('YYYY-MM-DD'),
  customerId: null,
  productDetails: [
    {
      date: null,
      productId: '',
      rate: null,
      discount: 0,
      quantity: null,
      amount: null,
      movementForId: '',
      movementType: 'SALE',
      companyId: '',
    },
  ],
  billNo: '',
  branchBillNo: '',
  billAmount: null,
  discount: 0,
  redeemablePoint: 0,
  toRedeem: false,
  redeemValue: 0,
  grossAmount: null,
  cgst: null,
  sgst: null,
  netAmount: null,
  totalAmount: null,
  paymentModes: [
    {
      date: moment().format('YYYY-MM-DD'),
      paymentType: 'Cash',
      amount: 0,
    },
  ],
  customerPaid: null,
  balanceRefund: null,
  chequeNumber: '',
  bankName: '',
  gstinLegalName: '',
  gstinNumber: '',
  remarks: '',
  isValueAddition: false,
  valueAdditionRemarks: '',
  valueAdditionAmount: null,
  isExchange: false,
  oldBillNo: '',
  oldProductDetails: [
    {
      date: null,
      productId: '',
      rate: 0,
      purchaseRate: 0,
      quantity: 0,
      amount: 0,
      movementForId: '',
      movementType: 'EXCHANGE',
      companyId: '',
    },
  ],
  cancelled: false,
  companyId: null,
  branchId: null,
  salesPersonId: null,
};

export interface SalesDiscountDetails {
  category: string;
  discountPercent: number;
}

export const salesQuery = `
  query SalesList($companyId: ID!, $branchId: ID, $date_Lte: Date, $first: Int, $after: String, $billNo: String) {
    allSales(companyId: $companyId, branchId: $branchId, date_Lte: $date_Lte, first: $first, after: $after, billNo_Icontains: $billNo) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          date
          customer {
            id
            name
            address
            contactNo
          }
          productDetails {
            edges {
              cursor
              node {
                id
                product {
                  id
                  category {
                    id
                    name
                  }
                  oldBarcodeId
                  name
                  unit {
                    id
                    name
                  }
                  colour
                  purchaseRate
                  sellingRate
                  hsnCode
                  hsn {
                    hsnCode
                  }
                  cgstRate
                  sgstRate
                  gstRate
                }
                rate
                purchaseRate
                gstRate
                discount
                quantity
                amount
                movementType
                movementFor {
                  id
                  name
                }
              }
            }
          }
          billNo
          branchBillNo
          billAmount
          discount
          redeemablePoint
          toRedeem
          redeemValue
          grossAmount
          cgst
          sgst
          netAmount
          totalAmount
          paymentModes(paymentForCredit: false) {
            edges {
              cursor
              node {
                id
                date
                paymentType
                amount
              }
            }
          }
          customerPaid
          balanceRefund
          chequeNumber
          bankName
          gstinLegalName
          gstinNumber
          remarks
          isValueAddition
          valueAdditionRemarks
          valueAdditionAmount
          isExchange
          oldBillNo
          oldProductDetails {
            edges {
              cursor
              node {
                id
                product {
                  id
                  category {
                    id
                    name
                  }
                  oldBarcodeId
                  name
                  hsnCode
                  hsn {
                    hsnCode
                  }
                }
                rate
                purchaseRate
                quantity
                amount
                movementType
                movementFor {
                  id
                  name
                }
              }
            }
          }
          cancelled
          saleBy
          billBy
          salesPerson {
            id
            name
          }
          branch {
            id
            name
          }
        }
      }
    }
  }
`;

export const dailySalesCentralQuery = `
query DailySalesCentralQuery($companyId: ID!, $startDate: Date!, $endDate: Date!) {
  dailySalesCentral(companyId: $companyId, startDate: $startDate, endDate: $endDate)
}
`;

export const monthlySalesReportQuery = `
query MonthlySalesReport($companyId: ID!, $year: Int!){
  monthlySalesReport(companyId: $companyId, year: $year)
}
`;

export const cashBankReportQuery = `
query CashBankReportQuery($companyId: ID!, $branchId: ID!, $startDate: Date!, $endDate: Date!){
  cashBankReport(companyId: $companyId, branchId: $branchId, startDate: $startDate, endDate: $endDate)
}
`;

export const dailySalesBranchQuery = `
query DailySalesBranchQuery($branchId: ID!, $startDate: Date!, $endDate: Date!) {
  dailySalesBranch(branchId: $branchId, startDate: $startDate, endDate: $endDate) {
    date
    billNo
    grossAmount
    totalAmount
    cgst
    sgst
    netAmount
    valueAdditionAmount
    exchangeAmount
    paymentMode
    creditAmountReceived
    customerName
    gstinNumber
    salesPersonName
  }
}
`;

export const monthlyGSTReportQuery = `
query MonthlyGSTReportQuery($companyId: ID!, $startDate: Date!, $endDate: Date!) {
  monthlyGstSalesReport(companyId: $companyId, startDate: $startDate, endDate: $endDate) {
    branch
    netAmount
    grossAmount
    cgst
    sgst
    netAmount
    exchangeAmount
    totalAmount
  }
}
`;

export const monthlyGSTSlabWiseReportQuery = `
query GstSlabWiseMonthlyReport($companyId: ID!, $branchId: ID!, $startDate: Date!, $endDate: Date!) {
  gstSlabWiseMonthlyReport(branchId: $branchId, companyId: $companyId, startDate: $startDate, endDate: $endDate) {
    hsnCode
    gst
    gstValue
    cgstValue
    sgstValue
    igstValue
  }
}
`;

export const creditSalesQuery = `
query CreditSales($companyId: ID!, $branchId: ID!, $startDate: Date!, $endDate: Date!) {
  creditReport(companyId: $companyId, branchId: $branchId, startDate: $startDate, endDate: $endDate) {
    id
    billDate
    billNo
    customerName
    netAmount
    creditAmount
  }
}
`;

export const updatePaymentModeForSalesMutation = `
mutation UpdatePaymentMode($input: UpdatePaymentModeForSalesInput!) {
  updatePaymentModeForSales(input: $input) {
    newPaymentMode {
      id
      date
    }
  }
}
`;

export const creditPaymentReportQuery = `
query CreditPaymentReport($companyId: ID, $branchId: ID, $startDate: Date, $endDate: Date, $after: String) {
  allPaymentModes(
    sales_CompanyId: $companyId,
    sales_BranchId: $branchId,
    sales_Date_Gte: $startDate,
    sales_Date_Lte: $endDate,
    paymentForCredit: true,
    first: 20,
    after: $after,
    orderBy: "sales__bill_no,sales__date,id",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        paymentType
        amount
        balanceAmount
        narration
        salesSet {
          edges {
            node {
              date
              customer {
                name
              }
              billNo
              netAmount
              paymentModes(paymentType_Iexact: "Credit") {
                edges {
                  node {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const paymentModeWiseReportBranch = `
query PaymentModeWiseReport($companyId: ID!, $branchId: ID!, $startDate: Date!, $endDate: Date!) {
  paymentModeListBranch(companyId: $companyId, branchId: $branchId, startDate: $startDate, endDate: $endDate) {
    date
    cash
    card
    credit
    cheque
    bank
    cod
    cancelSales
    totalForDate
  }
}
`;

export const paymentModeWiseReportCentral = `
query PaymentModeWiseReport($companyId: ID!, $startDate: Date!, $endDate: Date!) {
  paymentModeListCentral(companyId: $companyId, startDate: $startDate, endDate: $endDate) {
    branch
    cash
    card
    credit
    cheque
    bank
    cod
    cancelSales
    totalForBranch
  }
}
`;

export const masterDataQuery = `
query MasterData($companyId: ID!, $endDate: Date!, $after: Int!) {
  masterData(companyId: $companyId, endDate: $endDate, offset: $after, limit: 20) {
    branch
    branchGstin
    billNo
    date
    productId
    productName
    barcode
    customerName
    costPrice
    mrp
    grossSaleValue
    netSaleValue
    totalDiscountValue
    discountPercent
    category
    material
    quantity
    division
    hsnCode
    gstPercent
    gstValue
    cgst
    sgst
    taxableValue
    vendorName
    remarks
    unit
    gstinNumber
  }
}
`;

export const itemWiseDailySalesReportQuery = `
query ItemWiseDailySalesReportQuery($branchId: ID!, $startDate: Date!, $endDate: Date!, $companyId: ID!, $after: Int!) {
  itemWiseDailySalesReport(branchId: $branchId, startDate: $startDate, endDate: $endDate, companyId: $companyId, limit: 20, offset: $after) {
    date
    billNo
    customerName
    productName
    barcodeOld
    barcodeNew
    categoryName
    unit
    hsnCode
    quantity
    mrp
    netAmount
    gross
    discount
    gstinNumber
    gst
    taxableValue
    payableGst
    cgst
    sgst
  }
}
`;

export const salesCancelReportQuery = `
query SalesCancelReportQuery($companyId: ID, $after: String, $startDate: Date, $endDate: Date) {
  allProductDetails(
    companyId: $companyId,
    movementType: "SALE_CANCEL",
    first: 20,
    after: $after,
    date_Gte: $startDate,
    date_Lte: $endDate,
    orderBy: "productName",
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        product {
          name
          oldBarcodeId
          newBarcodeId
          category {
            name
          }
          division
        }
        quantity
        rate
        amount
        salesNewProductDetails {
          edges {
            node {
              date
              billNo
              branch {
                name
              }
            }
          }
        }
      }
    }
  }
}
`;

export interface CreditSales {
  startDate: string;
  endDate: string;
  paymentAmountObj: {
    [index: number]: string;
  };
  balanceAmountObj: {
    [index: number]: string;
  };
  paymentDateObj: {
    [index: number]: string;
  };
  paymentTypeObj: {
    [index: number]: string;
  };
  narrationObj: {
    [index: number]: string;
  };
}

export const creditSalesObject: CreditSales = {
  narrationObj: {},
  balanceAmountObj: {},
  startDate:
    moment().get('month') < 3
      ? `${moment().subtract(1, 'year').format('YYYY')}-04-01`
      : `${moment().get('year')}-04-01`,
  endDate: moment().format('YYYY-MM-DD'),
  paymentAmountObj: {},
  paymentDateObj: {},
  paymentTypeObj: {},
};

export const creditSalesError: CreditSales = {
  narrationObj: {},
  balanceAmountObj: {},
  startDate: '',
  endDate: '',
  paymentAmountObj: {},
  paymentDateObj: {},
  paymentTypeObj: {},
};

export interface SalesQueryVariable {
  companyId: string;
  branchId: string;
  date_Lte: string;
}

export interface SalesQueryResponse {
  data: {
    allSales: {
      edges: SalesEdges[];
    };
  };
}

export interface SalesEdges {
  cursor: string;
  node: SalesNodeEdgeQuery;
}

export interface AddSalesVariable {
  input: {
    sales: SalesNodeEdgeMutation;
  };
}

export const salesMutation = `
  mutation addSales($input: CreateSalesInput!) {
    createSales(input: $input){
      newSales {
        id
        date
        productDetails {
          edges {
            node {
              id
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
