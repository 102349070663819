import * as React from 'react';
import {
  DatePicker,
  Divider,
  Spin,
  Row,
  Col,
  Button,
  Skeleton,
  Table,
  Icon,
} from 'antd';
import moment from 'moment';
import Api, { GraphqlQuery } from 'requestapijs';
import { BranchNodeEdge } from '../master/Branch/constants';
import { CompanyNodeEdge } from '../master/Company/constants';
import { itemWiseSalesBranchQuery } from '../master/Product/constants';
import { ItemWiseDailySalesReportData, Int } from '../../schema';
import { Pagination } from 'sha-el-design';
import { TSInput } from '../common/TSInput';
import { HEADER, ITEM_WISE_DAILY_SALES_REPORT_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';
import { itemWiseDailySalesReportQuery } from './constants';
import { round } from 'lodash';

export class ItemWiseDailySalesReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      loading: true,
      currentPage: 1,
      after: 0,
      updateDataCount: 0,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Bill No.',
      key: 'bill_no',
      dataIndex: 'billNo',
    },
    {
      title: 'Customer Name',
      key: 'customerName',
      dataIndex: 'customerName',
    },
    {
      title: 'Product Name',
      key: 'product_name',
      dataIndex: 'productName',
    },
    {
      title: 'Barcode 1',
      key: 'barcode_old',
      dataIndex: 'barcodeOld',
    },
    {
      title: 'Barcode 2',
      key: 'barcode_new',
      dataIndex: 'barcodeNew',
    },
    {
      title: 'Category Name',
      key: 'category_name',
      dataIndex: 'categoryName',
    },
    {
      title: 'Unit',
      key: 'unit',
      dataIndex: 'unit',
    },
    {
      title: 'Hsn Code',
      key: 'hsn_code',
      dataIndex: 'hsnCode',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'MRP',
      key: 'mrp',
      dataIndex: 'mrp',
    },
    {
      title: 'Gross',
      key: 'gross',
      dataIndex: 'gross',
    },
    {
      title: 'Discount',
      key: 'discount',
      dataIndex: 'discount',
      render: (value) => round(value, 2),
    },
    {
      title: 'Net Amount',
      key: 'net_amount',
      dataIndex: 'netAmount',
    },
    {
      title: 'GSTIN Number',
      key: 'gstinNumber',
      dataIndex: 'gstinNumber',
    },
    {
      title: 'GST(%)',
      key: 'gst',
      dataIndex: 'gst',
    },
    {
      title: 'Taxable Value',
      key: 'taxable_value',
      dataIndex: 'taxableValue',
      render: (value) => round(value, 2),
    },
    {
      title: 'GST Value',
      key: 'payable_gst',
      dataIndex: 'payableGst',
      render: (value) => round(value, 2),
    },
    {
      title: 'CGST',
      key: 'cgst',
      dataIndex: 'cgst',
      render: (value) => round(value, 2),
    },
    {
      title: 'SGST',
      key: 'sgst',
      dataIndex: 'sgst',
      render: (value) => round(value, 2),
    },
  ];

  downloadCsv = (variables) => {
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(ITEM_WISE_DAILY_SALES_REPORT_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `item-wise-daily-sales-${new Date()}`,
          'ITEM WISE DAILY SALES REPORT',
          this.props.company.name,
          this.props.branch.name || '',
          moment(this.state.startDate).format('DD-MM-YYYY'),
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { startDate, endDate, currentPage, after, updateDataCount } =
      this.state;
    const companyId = this.props.company.id;
    const branchId = this.props.branch.id;
    const variables = {
      startDate,
      endDate,
      companyId,
      branchId,
      after,
      updateDataCount,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="Start Date"
              value={moment(this.state.startDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'startDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.endDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() > moment(this.state.endDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={moment(this.state.endDate)}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              disabledDate={(currentDate) => {
                if (!currentDate || !this.state.startDate) {
                  return false;
                }
                return (
                  currentDate.valueOf() < moment(this.state.startDate).valueOf()
                );
              }}
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        <GraphqlQuery
          queryString={itemWiseDailySalesReportQuery}
          variables={variables}
          render={(
            response: {
              itemWiseDailySalesReport: ItemWiseDailySalesReportData[];
            },
            error,
            loading,
          ) => {
            if (loading) {
              return <Skeleton active />;
            }
            if (error) {
              return <h3>Something went wrong</h3>;
            }
            if (response && companyId) {
              return [
                <Table
                  key="table"
                  dataSource={response.itemWiseDailySalesReport}
                  columns={this.columns()}
                  rowKey={(obj) => `${(obj.billNo, obj.productName)}`}
                  pagination={false}
                  style={{ overflowX: 'scroll' }}
                />,
                <Row key="Pagination">
                  <Col offset={21} span={1}>
                    <Button onClick={this.onPrev} disabled={currentPage === 1}>
                      Prev
                    </Button>
                  </Col>
                  <Col span={1} style={{ paddingLeft: 10 }}>
                    <Button type="primary">{currentPage}</Button>
                  </Col>
                  <Col span={1}>
                    <Button
                      onClick={this.onNext}
                      disabled={
                        !response.itemWiseDailySalesReport.length ||
                        response.itemWiseDailySalesReport.length < 20
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>,
              ];
            }
            return <Skeleton active />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
        ,
      </div>
    );
  }
}

interface Props {
  company: CompanyNodeEdge;
  branch: BranchNodeEdge;
}

interface State {
  startDate: string;
  endDate: string;
  loading: boolean;
  currentPage: number;
  after: number;
  updateDataCount: number;
}
