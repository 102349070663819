import * as React from 'react';
import { CompanyNodeEdge } from '../master/Company/constants';
import moment from 'moment';
import { GraphqlQuery } from 'requestapijs';
import {
  Skeleton,
  Table,
  Row,
  Col,
  Button,
  DatePicker,
  Divider,
  Icon,
} from 'antd';
import { masterDataQuery } from './constants';
import { MasterData } from '../../schema';
import { MASTER_DATA_CSV_URL } from '../../config';
import { downloadToFile } from '../helper';

export class MasterDataReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      endDate: moment().format('YYYY-MM-DD'),
      after: 0,
      currentPage: 1,
      updateDataCount: 0,
    };
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const nextState = { ...this.state };
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onNext = () => {
    let { currentPage, after } = this.state;
    currentPage = currentPage + 1;
    after = 20 * (currentPage - 1);
    this.setState({ currentPage, after });
  };

  onPrev = () => {
    let { currentPage, after } = this.state;
    if (currentPage === 1) {
      return;
    } else {
      currentPage = currentPage - 1;
      after = 20 * (currentPage - 1);
      this.setState({ currentPage, after });
    }
  };

  columns = () => [
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
    },
    {
      title: 'Branch GSTIN',
      dataIndex: 'branchGstin',
      key: 'branchGstin',
    },
    {
      title: 'Bill No',
      dataIndex: 'billNo',
      key: 'billNo',
    },
    {
      title: 'Bill Date',
      dataIndex: 'date',
      key: 'date',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
      key: 'hsnCode',
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'GSTIN Number',
      dataIndex: 'gstinNumber',
      key: 'gstinNumber',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Material',
      dataIndex: 'material',
      key: 'material',
    },
    {
      title: 'Division',
      dataIndex: 'division',
      key: 'division',
    },
    {
      title: 'Cost Price',
      dataIndex: 'costPrice',
      key: 'costPrice',
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      key: 'mrp',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Gross Sale Value',
      dataIndex: 'grossSaleValue',
      key: 'grossSaleValue',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: 'Total Discount Value',
      dataIndex: 'totalDiscountValue',
      key: 'totalDiscountValue',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: 'Discount(%)',
      dataIndex: 'discountPercent',
      key: 'discountPercent',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: 'Net Sale Value',
      dataIndex: 'netSaleValue',
      key: 'netSaleValue',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: 'Taxable Value',
      dataIndex: 'taxableValue',
      key: 'taxableValue',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: 'CGST',
      key: 'cgst',
      render: (value: MasterData) =>
        this.validateSameStateGstin(value.branchGstin, value.gstinNumber)
          ? value.cgst?.toFixed(2)
          : '0.00',
    },
    {
      title: 'SGST',
      key: 'sgst',
      render: (value: MasterData) =>
        this.validateSameStateGstin(value.branchGstin, value.gstinNumber)
          ? value.sgst?.toFixed(2)
          : '0.00',
    },
    {
      title: 'IGST',
      key: 'igst',
      render: (value: MasterData) =>
        !this.validateSameStateGstin(value.branchGstin, value.gstinNumber)
          ? value.gstValue?.toFixed(2)
          : '0.00',
    },
    {
      title: 'GST(%)',
      dataIndex: 'gstPercent',
      key: 'gstPercent',
    },
    {
      title: 'GST Value',
      dataIndex: 'gstValue',
      key: 'gstValue',
      render: (text) => Number(text).toFixed(2),
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'vendorName',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
    },
  ];

  validateSameStateGstin = (
    branchGst?: string | null,
    billGst?: string | null,
  ) => {
    if (!branchGst || !billGst) return true;

    return (
      branchGst.toUpperCase().slice(0, 2) === billGst.toUpperCase().slice(0, 2)
    );
  };

  downloadCsv = (variables) => {
    const startData =
      moment(this.state.endDate).get('month') < 3
        ? `01-04-${moment(this.state.endDate)
            .subtract(1, 'year')
            .format('YYYY')}`
        : `01-04-${moment(this.state.endDate).get('year')}`;
    let queryParams = '';
    for (const key in variables) {
      if (key in variables) {
        if (queryParams !== '') {
          queryParams += '&';
        }
        queryParams += key + '=' + encodeURIComponent(variables[key]);
      }
    }

    fetch(MASTER_DATA_CSV_URL + '?' + queryParams)
      .then((value) => value.text())
      .then((v) =>
        downloadToFile(
          v,
          `master-data-${new Date()}`,
          'MASTER DATA REPORT',
          this.props.company?.name || '',
          '',
          startData,
          moment(this.state.endDate).format('DD-MM-YYYY'),
          '',
        ),
      );
  };

  render() {
    const { endDate, after, currentPage, updateDataCount } = this.state;
    const { company } = this.props;
    const variables = {
      after,
      companyId: company?.id,
      endDate,
      updateDataCount,
    };
    return (
      <div>
        <Row>
          <Col span={5}>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="End Date"
              value={(endDate && moment(endDate)) || null}
              onChange={(date) =>
                this.onChange({
                  target: {
                    name: 'endDate',
                    value: date && moment(date).format('YYYY-MM-DD'),
                  },
                })
              }
              style={{ marginTop: '5px', width: '100%' }}
            />
          </Col>
        </Row>
        <Button
          onClick={() => {
            this.setState({ updateDataCount: updateDataCount + 1 });
          }}
          style={{ float: 'right' }}
          type="default"
        >
          <Icon type="retweet" />
        </Button>
        <Divider />
        <GraphqlQuery
          queryString={masterDataQuery}
          variables={variables}
          render={(response: { masterData: MasterData[] }, error, loading) => {
            if (loading) return <Skeleton active />;
            if (error) {
              return <h3>Something went wrong</h3>;
            }
            if (response) {
              return [
                <Table
                  key="table"
                  dataSource={response.masterData}
                  columns={this.columns()}
                  rowKey={(obj) => `${obj.billNo}${String(obj.productId)}`}
                  pagination={false}
                  style={{ overflowX: 'scroll' }}
                />,
                <Row key="Pagination">
                  <Col offset={21} span={1}>
                    <Button onClick={this.onPrev} disabled={currentPage === 1}>
                      Prev
                    </Button>
                  </Col>
                  <Col span={1} style={{ paddingLeft: 10 }}>
                    <Button type="primary">{currentPage}</Button>
                  </Col>
                  <Col span={1}>
                    <Button
                      onClick={this.onNext}
                      disabled={
                        !response.masterData.length ||
                        response.masterData.length < 20
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>,
              ];
            }
            return <Skeleton active />;
          }}
        />
        <Button
          key="download"
          style={{ float: 'right' }}
          icon="download"
          onClick={() => this.downloadCsv(variables)}
        />
      </div>
    );
  }
}

interface State {
  endDate: string;
  after: number;
  currentPage: number;
  updateDataCount: number;
}

interface Props {
  company: CompanyNodeEdge | null;
}
